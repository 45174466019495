import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import * as React from "react";
import { ReactComponent as QR } from "~assets/svg/qr.svg";
import { ReactComponent as Locker } from "~assets/svg/locker.svg";
import { ReactComponent as Cross } from "~assets/svg/cross.svg";
import { ReactComponent as Delete } from "~assets/svg/delete.svg";
import { ReactComponent as Map } from "~assets/svg/map.svg";
import {
  CanceledModal,
  Button,
  ButtonIcon,
  InfoField,
  Modal,
  QRCode,
  ConfirmModal,
} from "~components";
import { mobileMedia } from "~constants";
import { QRLockerModal } from "./BookLokerModal";
import { DateService } from "~services/Date";
import { Palette } from "~utils/Palette";
import { ResponseUserRecordQRLOCKER } from "prosto-api-client";
import { ReactComponent as Caution } from "~assets/svg/caution_circle.svg";

type SuccessfulRequestModalProps = {
  isWaitingBookLocker?: boolean;
  open: boolean;
  placeName: string;
  qrContent?: string;
  eventTitle?: string;
  date: Date;
  startTime: string;
  endTime?: number;
  userVerified?: boolean;
  disclaimer?: string;
  lockerInfo?: ResponseUserRecordQRLOCKER;
  geoLink?: string;
  onAddLocker?: () => Promise<void>;
  onClose: () => void;
  onCancelRegister: () => void;
};

export const SuccessfulRequestModal = ({
  open,
  onClose,
  placeName,
  date,
  startTime,
  endTime,
  qrContent,
  onCancelRegister,
  lockerInfo,
  userVerified,
  disclaimer,
  onAddLocker,
  isWaitingBookLocker,
  eventTitle,
  geoLink,
}: SuccessfulRequestModalProps) => {
  const { closeModal, isOpenedModal, openModal } = Modal.useModal();
  const {
    closeModal: closeInfoModal,
    isOpenedModal: isOpenedInfoModal,
    openModal: openInfoModal,
  } = Modal.useModal();
  const {
    closeModal: closeLockerModal,
    isOpenedModal: isOpenedLockerModal,
    openModal: openLockerModal,
  } = Modal.useModal();

  const formattedDate = DateService.fromJSDate(date)
    .toFormat("dd MMM", { locale: "ru" })
    .slice(0, 6);

  const lockerNumber = lockerInfo?.LOCKER_NUMBER;
  const handleCancelAppointment = () => {
    onCancelRegister();
    closeModal();
    openInfoModal();
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Container>
          <Header>
            <QRCodeAndButtonsBlock>
              {qrContent ? (
                <QRCode content={qrContent} />
              ) : (
                <QRPlaceholder>
                  QR-код сгенерируется в день записи
                </QRPlaceholder>
              )}
              <ButtonsBlock>
                <ButtonIcon Icon={Cross} onClick={onClose} />
                <ButtonIcon
                  Icon={Delete}
                  fill={Palette["Error/50"]}
                  onClick={openModal}
                />
              </ButtonsBlock>
            </QRCodeAndButtonsBlock>
            <Typography variant="h2">
              {userVerified ? (
                qrContent ? (
                  <>Покажи этот QR код, чтобы пройти в коворкинг</>
                ) : eventTitle ? (
                  eventTitle
                ) : (
                  <>Запись в коворкинг</>
                )
              ) : (
                <>
                  Покажи этот QR код администратору, чтобы верифицировать
                  аккаунт
                </>
              )}
            </Typography>
          </Header>
          {!userVerified ? (
            <InfoField>
              Ты пришёл в ПРОСТО впервые и нам нужно убедиться, что ты — это ты.
              {/* @ts-ignore */}
              <BlackText variant="body3" component="span">
                Обязательно подготовь оригинал паспорта, другие документы не
                подойдут,
              </BlackText>{" "}
              и покажи его администратору, чтобы он мог подтвердить твои данные.
              <AdditionalInfoText variant="body3">
                Без паспорта мы не сможем верифицировать аккаунт, а ты не
                сможешь пройти в пространство.
              </AdditionalInfoText>
            </InfoField>
          ) : (
            <DatePlaceInformation>
              <Place>
                <PlaceName>
                  <Typography variant="label3Thin">Точка</Typography>
                  <Typography variant="h3">{placeName}</Typography>
                </PlaceName>
                {geoLink && (
                  <ButtonIcon
                    Icon={Map}
                    onClick={() => {
                      window.open(geoLink);
                    }}
                  />
                )}
              </Place>
              <DateTime>
                <CellInfo>
                  <Typography variant="label3Thin">Дата</Typography>
                  <Typography variant="h3">{formattedDate}</Typography>
                </CellInfo>
                <CellInfo>
                  <Typography variant="label3Thin">Время</Typography>
                  <TimeView>
                    <Typography variant="h3">{startTime}</Typography>
                    {endTime && (
                      <>
                        <Hyphen />
                        <Typography variant="h3">{endTime}</Typography>
                      </>
                    )}
                  </TimeView>
                </CellInfo>
              </DateTime>
            </DatePlaceInformation>
          )}
          {lockerNumber && (
            <LockerInfo>
              <LockerInfoInnerContainer>
                <CellInfo>
                  <Typography variant="label3Thin">Локер</Typography>
                  <Typography variant="h4">{lockerNumber}</Typography>
                </CellInfo>
              </LockerInfoInnerContainer>
              <AddLockerButton variant="third">
                <ButtonInnerContainer>
                  <QR />
                  <AddLockerTextContainer onClick={openLockerModal}>
                    QR код для открытия локера
                  </AddLockerTextContainer>
                </ButtonInnerContainer>
              </AddLockerButton>
            </LockerInfo>
          )}
          {onAddLocker && (
            <AddLockerButton variant="third" loading={isWaitingBookLocker}>
              <ButtonInnerContainer>
                <Locker />
                <AddLockerTextContainer onClick={onAddLocker}>
                  Добавить локер
                </AddLockerTextContainer>
              </ButtonInnerContainer>
            </AddLockerButton>
          )}
          {disclaimer && (
            <Disclaimer variant="body3">
              <span>
                <Caution fill={Palette["B&W/100"]} />
              </span>
              <span>{disclaimer}</span>
            </Disclaimer>
          )}
        </Container>
      </Modal>
      <ConfirmModal
        open={isOpenedModal}
        onClose={closeModal}
        onCancel={handleCancelAppointment}
      />
      <CanceledModal
        open={isOpenedInfoModal}
        onClose={() => {
          closeInfoModal();
          onClose();
        }}
      />
      <QRLockerModal
        open={isOpenedLockerModal}
        onClose={closeLockerModal}
        qrContent={lockerInfo?.QR_DATA}
      />
    </>
  );
};

const Disclaimer = styled(Typography)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 18px 20px;
  border-radius: 15px;
  background: ${Palette["B&W/10"]};
`;

const QRPlaceholder = styled.div`
  display: flex;
  max-width: 150px;
  min-height: 150px;
  padding: 75px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: ${Palette["B&W/10"]};
  color: ${Palette["B&W/100"]};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 110% */
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;

  ${mobileMedia} {
    gap: 20px;
  }
`;

const QRCodeAndButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`;

const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const DatePlaceInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
`;

const Place = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

const PlaceName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  flex: 1 0 0;
`;

const DateTime = styled.div`
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  flex: 1 0 0;
`;

const CellInfo = styled.div`
  width: 110px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
`;

const TimeView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const Hyphen = styled.div`
  width: 15px;
  height: 3px;
  margin: 1px 0 5px;
  background: ${Palette["B&W/100"]};
`;

const LockerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const AddLockerButton = styled(Button)`
  width: 100%;
`;

const LockerInfoInnerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
  align-self: stretch;
`;

const ButtonInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
`;

const AddLockerTextContainer = styled.div`
  display: flex;
  padding: 3px 0;
  align-items: flex-start;
  gap: 10px;
`;

const BlackText = styled(Typography)`
  color: ${Palette["B&W/00"]};
  font-weight: 500;
`;

const AdditionalInfoText = styled(Typography)`
  color: ${Palette["B&W/00"]};
  padding-top: 20px;
`;
